import React from 'react';
import { useTranslation } from 'react-i18next';
import WaitlistForm from '../components/WaitlistForm';
import './Home.css';

const Home: React.FC = () => {
  const { t } = useTranslation();

  return (
    <div className="home">
      <section className="hero">
        <h1>{t('home.hero.title')}</h1>
        <p>{t('home.hero.subtitle')}</p>
        <WaitlistForm />
      </section>
      <section className="features">
        <h2>{t('home.features.title')}</h2>
        <div className="feature-grid">
          <div className="feature">
            <h3>{t('home.features.1.title')}</h3>
            <p>{t('home.features.1.description')}</p>
          </div>
          <div className="feature">
            <h3>{t('home.features.2.title')}</h3>
            <p>{t('home.features.2.description')}</p>
          </div>
          <div className="feature">
            <h3>{t('home.features.3.title')}</h3>
            <p>{t('home.features.3.description')}</p>
          </div>
        </div>
      </section>
    </div>
  );
};

export default Home;
import React from 'react';
import { useTranslation } from 'react-i18next';
import './Pricing.css';

const Pricing: React.FC = () => {
  const { t } = useTranslation();

  const plans = [
    {
      name: 'Basic',
      price: '$9.99',
      features: ['Smart Research Assistant', 'Personalized Learning', 'Basic Plagiarism Checker']
    },
    {
      name: 'Pro',
      price: '$19.99',
      features: ['All Basic features', 'Advanced Plagiarism Checker', 'Citation Generator', 'Virtual Study Groups']
    },
    {
      name: 'Enterprise',
      price: 'Custom',
      features: ['All Pro features', 'Dedicated Support', 'Custom Integrations', 'Team Management']
    }
  ];

  return (
    <div className="pricing-page">
      <h1>{t('pricing.title')}</h1>
      <div className="pricing-grid">
        {plans.map((plan, index) => (
          <div key={index} className="pricing-card">
            <h2>{t(`pricing.${plan.name}.title`)}</h2>
            <div className="price">{plan.price}</div>
            <ul>
              {plan.features.map((feature, featureIndex) => (
                <li key={featureIndex}>{t(`pricing.${plan.name}.features.${featureIndex}`)}</li>
              ))}
            </ul>
            <button>{t('pricing.selectPlan')}</button>
          </div>
        ))}
      </div>
    </div>
  );
};

export default Pricing;
import React from 'react';
import { useTranslation } from 'react-i18next';
import './Footer.css';

const Footer: React.FC = () => {
  const { t } = useTranslation();

  return (
    <footer className="footer">
      <div className="footer-content">
        <div className="footer-section">
          <h3>{t('footer.company')}</h3>
          <ul>
            <li><a href="/about">{t('footer.about')}</a></li>
            <li><a href="/careers">{t('footer.careers')}</a></li>
            <li><a href="/press">{t('footer.press')}</a></li>
          </ul>
        </div>
        <div className="footer-section">
          <h3>{t('footer.resources')}</h3>
          <ul>
            <li><a href="/blog">{t('footer.blog')}</a></li>
            <li><a href="/help">{t('footer.help')}</a></li>
            <li><a href="/api">{t('footer.api')}</a></li>
          </ul>
        </div>
        <div className="footer-section">
          <h3>{t('footer.legal')}</h3>
          <ul>
            <li><a href="/privacy">{t('footer.privacy')}</a></li>
            <li><a href="/terms">{t('footer.terms')}</a></li>
          </ul>
        </div>
      </div>
      <div className="footer-bottom">
        <p>&copy; 2024 DigitalCampus.live. {t('footer.rights')}</p>
      </div>
    </footer>
  );
};

export default Footer;
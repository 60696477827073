import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import './Contact.css';

const Contact: React.FC = () => {
  const { t } = useTranslation();
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [message, setMessage] = useState('');
  const [submitted, setSubmitted] = useState(false);

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    // Here you would typically send the data to your backend
    console.log('Submitted:', { name, email, message });
    setSubmitted(true);
  };

  if (submitted) {
    return <div className="contact-success">{t('contact.success')}</div>;
  }

  return (
    <div className="contact-page">
      <h1>{t('contact.title')}</h1>
      <form className="contact-form" onSubmit={handleSubmit}>
        <input
          type="text"
          placeholder={t('contact.namePlaceholder') as string}
          value={name}
          onChange={(e) => setName(e.target.value)}
          required
        />
        <input
          type="email"
          placeholder={t('contact.emailPlaceholder') as string}
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          required
        />
        <textarea
          placeholder={t('contact.messagePlaceholder') as string}
          value={message}
          onChange={(e) => setMessage(e.target.value)}
          required
        ></textarea>
        <button type="submit">{t('contact.submit')}</button>
      </form>
    </div>
  );
};

export default Contact;
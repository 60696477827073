import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import './WaitlistForm.css';

const WaitlistForm: React.FC = () => {
  const { t } = useTranslation();
  const [email, setEmail] = useState('');
  const [name, setName] = useState('');
  const [submitted, setSubmitted] = useState(false);

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    // Here you would typically send the data to your backend
    console.log('Submitted:', { name, email });
    setSubmitted(true);
  };

  if (submitted) {
    return <div className="waitlist-success">{t('waitlist.success')}</div>;
  }

  return (
    <form className="waitlist-form" onSubmit={handleSubmit}>
      <h2>{t('waitlist.title')}</h2>
      <p>{t('waitlist.description')}</p>
      <input
        type="text"
        placeholder={t('waitlist.namePlaceholder') as string}
        value={name}
        onChange={(e) => setName(e.target.value)}
        required
      />
      <input
        type="email"
        placeholder={t('waitlist.emailPlaceholder') as string}
        value={email}
        onChange={(e) => setEmail(e.target.value)}
        required
      />
      <button type="submit">{t('waitlist.submit')}</button>
    </form>
  );
};

export default WaitlistForm;
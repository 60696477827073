import React from 'react';
import { useTranslation } from 'react-i18next';
import LanguageSelector from './LanguageSelector';
import './Header.css';

const Header: React.FC = () => {
  const { t } = useTranslation();

  return (
    <header className="header">
      <div className="logo">
        <img src="/digitalcampus-logo.png" alt="DigitalCampus.live Logo" />
        <h1>{t('header.title')}</h1>
      </div>
      <LanguageSelector />
    </header>
  );
};

export default Header;
import React from 'react';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import './NavigationBar.css';

const NavigationBar: React.FC = () => {
  const { t } = useTranslation();

  return (
    <nav className="navigation-bar">
      <ul>
        <li><Link to="/">{t('nav.home')}</Link></li>
        <li><Link to="/features">{t('nav.features')}</Link></li>
        <li><Link to="/pricing">{t('nav.pricing')}</Link></li>
        <li><Link to="/contact">{t('nav.contact')}</Link></li>
      </ul>
    </nav>
  );
};

export default NavigationBar;
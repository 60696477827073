import React from 'react';
import { useTranslation } from 'react-i18next';
import './Features.css';

const Features: React.FC = () => {
  const { t } = useTranslation();

  const features = [
    {
      title: 'Smart Research Assistant',
      description: 'Quickly find and summarize relevant academic papers using advanced AI algorithms.',
      icon: '🔍'
    },
    {
      title: 'Personalized Learning',
      description: 'Adapt to your learning style and pace with customized study plans and recommendations.',
      icon: '📚'
    },
    {
      title: 'Collaborative Projects',
      description: 'Seamlessly work with peers on group assignments using real-time collaboration tools.',
      icon: '👥'
    },
    {
      title: 'Plagiarism Checker',
      description: 'Ensure academic integrity with our advanced plagiarism detection system.',
      icon: '✅'
    },
    {
      title: 'Citation Generator',
      description: 'Automatically generate accurate citations in various formats for your research papers.',
      icon: '📝'
    },
    {
      title: 'Virtual Study Groups',
      description: 'Connect with students worldwide and form virtual study groups for peer learning.',
      icon: '🌍'
    }
  ];

  return (
    <div className="features-page">
      <h1>{t('features.title')}</h1>
      <div className="features-grid">
        {features.map((feature, index) => (
          <div key={index} className="feature-card">
            <div className="feature-icon">{feature.icon}</div>
            <h2>{t(`features.${index + 1}.title`)}</h2>
            <p>{t(`features.${index + 1}.description`)}</p>
          </div>
        ))}
      </div>
    </div>
  );
};

export default Features;